<template>
  <div id="Aluno_Meus_Talentos" class="pl-1">
    <transition-group name="fade">
      <div v-if="show" key="Aluno_Meus_Talentos">
        <meusDadosComponents
          :bg_color="'bg-edf0f5'"
          :targetDoing="'Meus Dados'"
          :container="'container'"
          :px="'px-5'"
          :alterar_foto="true"
          :btn_editar_meus_dados="true"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import meusDadosComponents from "../components/meusDadosComponent";
export default {
  data() {
    return {
      show: false,
    };
  },
  components: {
    meusDadosComponents,
  },
  mounted() {
    this.show = true;
  },
};
</script>
